import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "twin.macro"
import Imgix from "react-imgix"

import ContentGrid from "../components/contentGrid"
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const BlogPost = ({ data, pageContext, location }) => {

  return(
    <Layout workPage={true}>
      <Seo 
        title={data.microcmsArticles.title} 
        description={`${data.microcmsArticles.client_name}様・${data.microcmsArticles.subtitle}`} workPage={true}
        pagePath={location.pathname}
        thumbPath={data.microcmsArticles.featured_image.url}
      />
        <ContentGrid>
        <article className="work_content mt-40">
          <time tw="font-en text-7xl md:text-9xl text-gold">{data.microcmsArticles.workday}</time>
          <h2 tw="text-gold text-lg">{data.microcmsArticles.title}</h2>
          <h3 tw="text-gold font-normal">{data.microcmsArticles.subtitle}</h3>

          {
            data.microcmsArticles.client_name !=null && (
              <div tw="mt-10 py-6 border-t border-b border-gold">
                <h3 tw="font-normal">詳細情報</h3>
                  <ul tw="mt-2">
                    {
                      data.microcmsArticles.client_name && <li tw="mt-2">Client : {data.microcmsArticles.client_name}</li>
                    }
                    {
                      data.microcmsArticles.client_url && <li tw="mt-2">Client URL : <a href={data.microcmsArticles.client_url} target="_blank" rel="noopener noreferrer" tw="underline hover:no-underline">{data.microcmsArticles.client_url}</a></li>
                    }
                    {
                      data.microcmsArticles.client_address && <li tw="mt-2">Location : {data.microcmsArticles.client_address}</li>
                    }
                  </ul>
              </div>
            )
          }
          {
            data.microcmsArticles.body && <p tw="mt-10">{data.microcmsArticles.body}</p>
          }
          <div tw="mt-10">
              <Imgix
                src={data.microcmsArticles.featured_image.url}
                sizes="(max-width: 800px) 100vw, 800px"
                htmlAttributes={{
                  alt: "",
                }}
                tw="m-0 w-full"
              />
            {
              data.microcmsArticles.image_01 && (
                <ResponsiveMasonry
                  columnsCountBreakPoints={{350: 1, 750: 2}}
                  gutter={0}
                >
                  <Masonry>
                    <Imgix
                      src={data.microcmsArticles.image_01.url}
                      sizes="(max-width: 800px) 100vw, 600px"
                      htmlAttributes={{
                        alt: "",
                      }}
                      tw="m-0"
                    />
                    {
                      data.microcmsArticles.image_02 && 
                      <Imgix
                        src={data.microcmsArticles.image_02.url}
                        sizes="(max-width: 800px) 100vw, 600px"
                        htmlAttributes={{
                          alt: "",
                        }}
                        tw="m-0"
                      />
                    }
                    {
                      data.microcmsArticles.image_03 && 
                      <Imgix
                        src={data.microcmsArticles.image_03.url}
                        sizes="(max-width: 800px) 100vw, 600px"
                        htmlAttributes={{
                          alt: "",
                        }}
                        tw="m-0"
                      />
                    }
                    {
                      data.microcmsArticles.image_04 && 
                      <Imgix
                        src={data.microcmsArticles.image_04.url}
                        sizes="(max-width: 800px) 100vw, 600px"
                        htmlAttributes={{
                          alt: "",
                        }}
                        tw="m-0"
                      />
                    }
                  </Masonry>
                </ResponsiveMasonry>
              )
            }
          </div>
          <ul tw="grid grid-cols-2 gap-x-6 mt-20">
            {
              pageContext.next && (
                <li tw="col-start-1 col-end-2">
                  <Link 
                    to={`/works/${pageContext.next.id}/`} 
                    rel="prev" 
                    title={pageContext.next.title}
                    tw="
                      h-40
                      overflow-hidden
                      block
                      relative
                      rounded
                    "
                    className="group"
                  >
                    <Imgix
                      src={pageContext.next.featured_image.url}
                      sizes="(max-width: 800px) 100vw, 400px"
                      htmlAttributes={{
                        alt: `${pageContext.next.title}`,
                      }}
                      tw="w-full h-full object-cover"
                    />
                    <span
                      tw="
                        transition-colors
                        absolute 
                        top-0 
                        left-0 
                        w-full 
                        h-full 
                        bg-black
                        bg-opacity-50
                        group-hover:bg-opacity-0
                      "
                    ></span>
                    <span tw="
                      flex 
                      justify-center 
                      items-center 
                      absolute 
                      top-0 
                      left-0 
                      w-full 
                      h-full 
                      p-4
                      text-white
                      text-center
                      text-sm
                    ">{pageContext.next.title}</span>
                  </Link>
                </li>
              )
            }
            {
              pageContext.previous && (
                <li tw="col-start-2 col-end-3">
                  <Link 
                    to={`/works/${pageContext.previous.id}/`} 
                    rel="prev" 
                    title={pageContext.previous.title}
                    tw="
                      h-40
                      overflow-hidden
                      block
                      relative
                      rounded
                    "
                    className="group"
                  >
                    <Imgix
                      src={pageContext.previous.featured_image.url}
                      sizes="(max-width: 800px) 100vw, 400px"
                      htmlAttributes={{
                        alt: `${pageContext.previous.title}`,
                      }}
                      tw="w-full h-full object-cover"
                    />
                    <span
                      tw="
                        transition-colors
                        absolute 
                        top-0 
                        left-0 
                        w-full 
                        h-full 
                        bg-black
                        bg-opacity-50
                        group-hover:bg-opacity-0
                      "
                    ></span>
                    <span tw="
                      flex 
                      justify-center 
                      items-center 
                      absolute 
                      top-0 
                      left-0 
                      w-full 
                      h-full 
                      p-4
                      text-white
                      text-center
                      text-sm
                    ">{pageContext.previous.title}</span>
                  </Link>
                </li>
              )
            }
          </ul>
          <footer tw="py-10 pb-20">
            <small tw="font-en text-gold text-lg tracking-wider">© Yumiko Kinjo</small>
          </footer>
        </article>
        
      </ContentGrid>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($id: String!) {
    microcmsArticles(id: { eq: $id }) { 
      title
      subtitle
      workday(formatString: "YYYY")
      featured_image {
        url
      }
      client_name
      client_url
      client_address
      body
      image_01 {
        url
      }
      image_02 {
        url
      }
##      image_03 {
##        url
##      }
##      image_04 {
##        url
##      }
    }
  }
`
